import 'react-multi-carousel/lib/styles.css';

export const About = () => {
	const aboutText = "Somos uma equipe unida pelo desejo de inovar e deixar nossa marca no mercado de games brasileiro." + 
	"Nosso foco é desenvolver jogos que se destaquem por sua qualidade e estética inigualável." + 
	"Adotamos uma abordagem artística e cinematográfica, buscando criar experiências envolventes e emocionantes para os jogadores." + 
	"Nosso objetivo é transcender o convencional, explorando novas possibilidades e elevando o padrão dos jogos desenvolvidos no Brasil.";

  return (
    <section className="about" id="about">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="about-bx wow zoomIn">
                        <h1>Sobre Nós</h1>
                        <p>{aboutText}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
