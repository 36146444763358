import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from "../assets/img/nav-icon4.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={6} className="text-center text-sm-start mb-3 mb-sm-0">
            <img src={logo} alt="Logo" style={{ maxWidth: '120px', minWidth: '100px' }} />
          </Col>
          <Col xs={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.instagram.com/eusolyy/reels/" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="Instagram" /></a>
              <a href="https://www.linkedin.com/company/solo-game-studio/" target="_blank" rel="noopener noreferrer"><img src={navIcon4} alt="LinkedIn" /></a>
              <a href="https://discord.gg/fcezAC2UpV" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="Discord" /></a>
              <a href="https://www.tiktok.com/@eusolyy?lang=en" target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="TikTok" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
