import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import video1 from '../assets/vid/video1.mp4';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [
    "enfrentar seus medos?", 
    "desvendar os mistérios?", 
    "sobreviver às sombras?", 
    "escapar do desconhecido?", 
    "ouvir os sussurros da morte?", 
    "explorar locais amaldiçoados?"
  ];
  
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Solo Studio</span>
                <h1 className="glitch" data-text="Mergulhe na Escuridão">{`Submeta-se a Escuridão`}</h1>
                <h1>Pronto para <span className="txt-rotate"><span className="wrap">{text}</span></span></h1>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <video 
                  className={isVisible ? "animate__animated video-bg" : "video-bg"}
                  loop
                  muted
                  autoPlay
                  playsInline>
                    <source src={video1} type="video/mp4"/>
                  </video>
                }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
