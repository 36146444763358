import React from 'react';
import { Col, Row } from "react-bootstrap";
import contactImage from "../assets/img/contact.png";


export const Contact = () => {
  const emailAddress = "eusolodev@gmail.com";  // Substitua pelo seu endereço de e-mail real

  return (
      <Col lg={12}>
        <div className="contact-bx"  id="contact">
          <Row>
            <Col lg={12}>
              <h1 className="contact-title">Contato</h1>
              <h2 className="contact-subtitle">Fale Conosco</h2>
              <p className="contact-email"><a href={`mailto:${emailAddress}`} className="email-link">{emailAddress}</a></p>
            </Col>
            <img src={contactImage} alt="Contact" className="contact-img" />
          </Row>
        </div>
      </Col>
  )
}
