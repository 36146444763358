import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";

import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  
  const studioText1 = "Fundado no início de 2024 por Solyy, o Solo Game Studio se tornou um refúgio para talentos criativos e apaixonados por games. Nenhum de nós se conhecia antes deste projeto, mas a visão compartilhada e o entusiasmo pelo jogo nos uniram em uma equipe coesa.";	
  const studioText2 = " Nossa missão é criar jogos que sejam verdadeiras obras de arte interativas, mantendo um ambiente colaborativo e estimulante, onde cada novo projeto é uma expressão de nossa paixão coletiva e dedicação ao mundo dos games.";
  
  const membersText1 = "Embora sejamos amadores, nossa equipe é formada por indivíduos talentosos em várias disciplinas essenciais ao desenvolvimento de jogos, como modelagem 3D, programação e edição de vídeo. Alguns de nós dedicam-se exclusivamente ao projeto, impulsionados pela vontade de contribuir para a evolução da indústria de jogos brasileira. Juntos, formamos um grupo diversificado e comprometido, cada um trazendo habilidades essenciais para o sucesso e inovação do nosso estúdio.";

  const projects = [
    {
      title: "Design e Desenvolvimento",
      description: "",
      imgUrl: projImg1,
    },
    {
      title: "Design e Desenvolvimento",
      description: "",
      imgUrl: projImg2,
    },
    {
      title: "Design e Desenvolvimento",
      description: "",
      imgUrl: projImg3,
    },
    {
      title: "Design e Desenvolvimento",
      description: "",
      imgUrl: projImg4,
    },
    {
      title: "Design e Desenvolvimento",
      description: "",
      imgUrl: projImg5,
    },
    {
      title: "Design e Desenvolvimento",
      description: "",
      imgUrl: projImg6,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h1>Estudio</h1>
                <p>{studioText1 + studioText2}</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Projetos</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Membros</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="members-bx">
                        <p>{membersText1}</p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
